import { Component, Prop } from 'vue-property-decorator';

import { Headline } from '~/components/atoms';
import Button, { Style } from '~/components/atoms/button/Button';
import Image from '~/components/atoms/image/Image';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';
import { CztWidgets } from '~/utils/views/widgets';
import { SliderInterface, SliderItemInterface } from './types';
import { ThemeRatios } from '~/utils/theme';
import { VueComponent } from '~/utils/vue-component';

import style from './Slider.scss';
import Banner from '../banner/Banner';

@Component({
  style,
})
export default class Slider extends VueComponent<SliderInterface>
  implements SliderInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public anchorName?: string;

  @Prop({ required: true, type: Array })
  public items!: SliderItemInterface[];

  @Prop({ default: 0, type: Number })
  public delay!: number;

  @Prop({ type: Boolean, default: false })
  public contentWidth!: boolean;

  @Prop({ type: Boolean, default: false })
  public isFirst!: boolean;

  @Prop()
  public ratio?: ThemeRatios;

  public className = CztWidgets.SLIDER;

  protected rootClass: string = 'czt-slider';

  protected activeSlide: number = 0;
  protected preloadMap: number[] = [];

  protected get interval(): number {
    return this.delay * 1000;
  }

  public mounted() {
    this.preloadNeighbours();
  }

  public render() {
    if (this.items.length < 1) {
      return;
    }
    const content =
      this.items.length === 1
        ? this.buildBanner(this.items[0], 0, true)
        : this.buildSlider();

    return this.contentWidth ? (
      <v-container id={this.anchorId}>
        <v-row>
          <v-col cols={12}>{content}</v-col>
        </v-row>
      </v-container>
    ) : (
      <div id={this.anchorId}>{content}</div>
    );
  }

  protected preloadNeighbours() {
    const nextSlide = (this.activeSlide + 1) % this.items.length;
    const previousSlide =
      this.activeSlide === 0 ? this.items.length - 1 : this.activeSlide - 1;

    if (this.preloadMap.indexOf(nextSlide) < 0) {
      this.preloadMap.push(nextSlide);
    }
    if (this.preloadMap.indexOf(previousSlide) < 0) {
      this.preloadMap.push(previousSlide);
    }
  }

  protected buildSlider(): JSX.Element {
    const classes = [this.rootClass];
    if (this.ratio) {
      classes.push(`${this.rootClass}--has-ratio`);
    }
    if (this.isFirst) {
      classes.push('czt-first-widget');
    }
    return (
      <v-carousel
        class={classes.join(' ')}
        cycle={!!this.interval}
        height={'auto'}
        hide-delimiter-background
        hide-delimiters
        interval={!!this.interval ? this.interval : undefined}
        onChange={this.preloadNeighbours}
        show-arrows-on-hover
        v-model={this.activeSlide}
      >
        {this.items.map((item: SliderItemInterface, key: number) => {
          return (
            <v-carousel-item
              class={`${this.rootClass}__item`}
              key={item.title + key}
              eager={this.preloadMap.indexOf(key) > -1}
            >
              {this.buildBanner(item, key)}
            </v-carousel-item>
          );
        })}
      </v-carousel>
    );
  }

  protected buildBanner(
    item: SliderItemInterface,
    index: number,
    isBanner?: boolean
  ): JSX.Element {
    const classes = [this.rootClass];
    if (this.ratio) {
      classes.push(`${this.rootClass}--has-ratio`);
    }
    if (this.isFirst) {
      classes.push('czt-first-widget');
    }
    const isLongContent = this.ratio === 3 && item.content.length > 350;
    const light =
      item.backgroundImage.src || item.video?.videoId ? true : false;
    const sideContent =
      item.sideImage || item.sideContent ? (
        <div slot='sideContent'>
          {item.sideImage && (
            <Image
              src={item.sideImage.src}
              alt={item.title}
              isFirst={this.isFirst && index === 0}
            />
          )}
          {item.sideContent && (
            <HtmlRenderer content={item.sideContent} light={light} side />
          )}
        </div>
      ) : null;
    const superTitle = item.superTitle ? (
      <div slot='superTitle'>{item.superTitle}</div>
    ) : null;
    const socialButtons = item.socialButtons ? (
      <div slot='socialButtons'>{item.socialButtons}</div>
    ) : null;

    const bannerContent = (
      <Banner
        isFirst={this.isFirst && index === 0}
        backgroundImage={item.backgroundImage}
        imageFilter={item.imageFilter}
        title={!item.hideTitle ? item.title : ''}
        titleLevel={item.largeHeadline ? 1 : undefined}
        video={item.video}
        titleInColumn={item.sideImage ? true : false}
        ratio={this.ratio}
        showVideoControls={item.showVideoControls}
        imageSource={item.imageSource}
      >
        {item.largeHeadline ? (
          <Headline level={3} light>
            <HtmlRenderer content={item.content} />
          </Headline>
        ) : (
          <div class={`${this.rootClass}__description`}>
            <HtmlRenderer content={item.content} light={light} />
          </div>
        )}

        {(() => {
          if (item.button && item.button.url && item.button.label) {
            return (
              <Button
                class={isLongContent ? `${this.rootClass}__btn` : undefined}
                url={item.button.url}
                buttonStyle={Style.PRIMARY_LIGHT}
              >
                {item.button.label}
              </Button>
            );
          }
        })()}
        {sideContent}
        {superTitle}
        {socialButtons}
      </Banner>
    );
    return isBanner ? (
      <div class={classes.join(' ')}>{bannerContent}</div>
    ) : (
      bannerContent
    );
  }
}
